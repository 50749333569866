<template>
  <div class="w-100 d-flex flex-wrap grid-items">
    <div class="mb-4 w-100">
      <div class="d-flex align-items-center">
        <span
          class="font-weight-bold text-capitalize text-lsm text-secondary mr-2"
          >Top Posts
        </span>
        <i
          class="fa fa-info-circle text-skygray"
          v-b-popover.hover="{
            variant: 'primary',
            content: 'Posts published by day and hour',
          }"
        ></i>
      </div>
    </div>
    <div class="row px-lg-3">
      <div
        class="top-post-box col px-2"
        v-for="(post, index) in posts"
        :key="index"
      >
        <div class="top-post-box__content">
          <div
            class="text-white w-100 top-post-box__content--top d-flex align-items-end justify-content-between px-3 py-2"
            :class="[`bg-${post.rrss}`]"
          >
            <div>
              <i :class="`fab fa-${post.rrss} mr-2 text-lg`"></i>
              <span class="text-lsm">Interactions</span>
            </div>
            <span class="font-bold text-3-5xl">{{
              numFormatter(calcInteractions(post.interactions))
            }}</span>
          </div>
          <div class="d-flex p-3 align-items-center">
            <img
              :src="post.images.profile"
              style="width: 40px; height: 40px; float: left"
              class="mr-3 rounded-circle"
            />
            <div>
              <p class="text-xs text-secondary">
                {{ post.name }}
              </p>
              <p class="text-skygray text-xxs">{{ post.date }}</p>
            </div>
          </div>
          <div class="text-xs px-4">
            <p class="text-justify text-color-text">{{ post.post }}</p>
          </div>
          <div v-if="post.rrss !== 'twitter'" class="my-2">
            <a target="_blank" :href="post.url_post">
              <img
                :src="post.images.posts"
                alt=""
                class="max-h-70 w-100 object-cover"
              />
            </a>
          </div>
          <div class="py-2 px-4 text-skygray">
            <!-- Global -->
            <div
              v-if="post.rrss !== 'youtube' && post.rrss !== 'linkedin' && post.rrss !== 'instagram'"
              class="d-flex justify-content-between align-items-center text-color-text"
            >
              <div>
                <i
                  class="text-xs far fa-share-square mr-2 d-inline-block text-xs"
                ></i>
                <span class="text-xs"> Shares </span>
              </div>
              <span class="text-xs">{{ post.interactions.shares }}</span>
            </div>

            <cp-icon-reaction
                name="Comments"
                :amount="post.interactions.comments"
                :icon="'far fa-comments'"
              />
            <!-- End Global -->
            <!-- Facebook -->
            <div v-if="post.rrss === 'facebook'">
              <cp-icon-reaction :amount="post.interactions.reactions.like" />
              <cp-icon-reaction
                name="Love"
                :amount="post.interactions.reactions.love"
                :icon="'far fa-heart'"
              />
              <cp-icon-reaction
                name="Ha-ha"
                :amount="post.interactions.reactions.haha"
                :icon="'far fa-grin-squint'"
              />
              <cp-icon-reaction
                name="Sad"
                :amount="post.interactions.reactions.sad"
                :icon="'far fa-frown'"
              />
              <cp-icon-reaction
                name="Angry"
                :amount="post.interactions.reactions.angry"
                :icon="'far fa-angry'"
              />
              <cp-icon-reaction
                name="Wow"
                :amount="post.interactions.reactions.wow"
                :icon="'far fa-surprise'"
              />
            </div>
            <!-- Twitter -->
            <div v-if="post.rrss === 'twitter'" > 
              <cp-icon-reaction
                name="Likes"
                :icon="'far fa-heart'"
                :amount="post.interactions.reactions.like"
              />
              <cp-icon-reaction
                name="Retweet"
                :amount="post.interactions.retweet"
                :icon="'fa fa-retweet'"
              />
            </div>
            <!-- Instagram -->
            <div  v-if="post.rrss === 'instagram'">
              <cp-icon-reaction
                  name="Likes"
                  :icon="'far fa-heart'"
                  :amount="post.interactions.reactions.like"
                />
            </div>
            <!-- Youtube -->
            <div v-if="post.rrss === 'youtube'">
              <cp-icon-reaction :amount="post.interactions.reactions.like" />
              <cp-icon-reaction
                name="Dislike"
                :amount="post.interactions.reactions.dislike"
                :icon="'far fa-thumbs-down'"
              />
            </div>
            <!-- Linkedin -->
            <div v-if="post.rrss === 'linkedin'">
              <cp-icon-reaction
                name="Support"
                :amount="post.interactions.shares"
                :icon="'fa fa-hand-holding-heart'"
              />
              <cp-icon-reaction :amount="post.interactions.reactions.like" />
              <cp-icon-reaction
                name="Celebrate"
                :amount="post.interactions.reactions.haha"
                :icon="'fa fa-glass-cheers'"
              />
              <cp-icon-reaction
                name="Funny"
                :amount="post.interactions.reactions.wow"
                :icon="'far fa-laugh'"
              />
              <!-- Curious (far fa-surprise) -->
              <cp-icon-reaction
                name="Love"
                :amount="post.interactions.reactions.love"
                :icon="'far fa-heart'"
              />
              <cp-icon-reaction
                name="Insightful"
                :amount="post.interactions.reactions.sad"
                :icon="'far fa-lightbulb'"
              />
            </div>

          </div>
        </div>
      </div>
    </div>
    <div v-if="posts.length == 0"
      class="image-container-off"
    >
      <img
        class="spacing mb-4 rounded shadow opacity-50"
        src="@/assets/images/off-top-post.png"
        alt=""
      />
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
  </div>
</template>

<script>
import { Helpers, mapGetters } from "@isc/styleguide";
import CpIconReaction from "./components/CpIconReaction.vue";
export default {
  name: "PostResume",
  components: {
    CpIconReaction,
  },
  props: {
    posts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      numFormatter: (num) => Helpers.numFormatter(num),
    };
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOff",
      titleImgOff: "global/helper/getTitleImgOff",
    })
  },
  methods: {
    calcInteractions(interactions) {
      let count = 0;
      count += interactions.shares;
      count += interactions.comments;
      count += interactions.retweet;
      const { like, sad, wow, angry, dislike, love, haha } =
        interactions.reactions;
      count += like + sad + wow + angry + dislike + love + haha;
      return count;
    }
  },
};
</script>

<style scoped>
.grid-items .spacing:nth-child(3n) {
  margin-right: 2%;
  margin-left: 2%;
}
.max-h-60 {
  max-height: 15rem;
}
.top-post-box__content {
  border-radius: 6px;
  box-shadow: 0px 2px 3.9px 0.1px rgba(0, 0, 0, 0.15);
}
.top-post-box__content--top {
  border-radius: 6px 6px 0 0;
}
</style>
