var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "w-100 px-5" }, [
        _vm.step === 1
          ? _c(
              "div",
              {
                staticClass: "d-flex align-items-center flex-column py-4 w-100",
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-primary font-weight-bold text-center mb-4",
                  },
                  [_vm._v("Set the name of the account")]
                ),
                _c("b-form-input", {
                  staticClass: "w-1/3",
                  attrs: { placeholder: "Example Name" },
                  model: {
                    value: _vm.form.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "name", $$v)
                    },
                    expression: "form.name",
                  },
                }),
                _vm.isExistedAccount
                  ? _c("div", { staticClass: "message-alert" }, [
                      _c("i", { staticClass: "fa fa-info-circle" }),
                      _vm._v(" That account name already exists "),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.step === 2
          ? _c(
              "div",
              {
                staticClass: "d-flex align-items-center flex-column py-4 w-100",
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-primary font-weight-bold text-center mb-4",
                  },
                  [_vm._v("Add channels to your account")]
                ),
                _c("div", { staticClass: "d-flex justify-content-center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "mr-4 text-facebook",
                      attrs: { href: "javascript:" },
                      on: {
                        click: function ($event) {
                          return _vm.openDialogLogin("facebook")
                        },
                      },
                    },
                    [_c("i", { staticClass: "fab fa-facebook fa-lg" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "mr-4 text-instagram",
                      attrs: { href: "javascript:" },
                      on: {
                        click: function ($event) {
                          return _vm.openDialogLogin("instagram")
                        },
                      },
                    },
                    [_c("i", { staticClass: "fab fa-instagram fa-lg" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "mr-4 text-twitter",
                      attrs: { href: "javascript:" },
                      on: {
                        click: function ($event) {
                          return _vm.openDialogLogin("twitter")
                        },
                      },
                    },
                    [_c("i", { staticClass: "fab fa-twitter fa-lg" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "mr-4 text-youtube",
                      attrs: { href: "javascript:" },
                      on: {
                        click: function ($event) {
                          return _vm.openDialogLogin("youtube")
                        },
                      },
                    },
                    [_c("i", { staticClass: "fab fa-youtube fa-lg" })]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "mr-4 text-linkedin",
                      attrs: { href: "javascript:" },
                      on: {
                        click: function ($event) {
                          return _vm.openDialogLogin("linkedin")
                        },
                      },
                    },
                    [_c("i", { staticClass: "fab fa-linkedin fa-lg" })]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "my-4 flex flex-column w-100 mb-0" },
                  [
                    _vm._l(_vm.accounts, function (account, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "py-1 mb-2 border-bottom w-50 mx-auto d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("span", [
                            _c("i", {
                              class:
                                "fab fa-" +
                                account.socialNetwork +
                                " fa-lg text-" +
                                account.socialNetwork +
                                " mr-2",
                            }),
                            _vm._v(" " + _vm._s(account.accountName) + " "),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "h2",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeAccount(
                                    account.socialNetworkId
                                  )
                                },
                              },
                            },
                            [_vm._v("×")]
                          ),
                        ]
                      )
                    }),
                    _vm.accounts.length > 0 &&
                    _vm.accounts[0].socialNetwork === "facebook"
                      ? _c(
                          "div",
                          { staticClass: "pt-05" },
                          [
                            _c("CpWarningMessage", {
                              attrs: {
                                justifyContent: "center",
                                message:
                                  "You should have at least 10 followers for you to choose the user name in the url.",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            )
          : _vm._e(),
        _vm.step === 3
          ? _c(
              "div",
              {
                staticClass: "d-flex align-items-center flex-column py-4 w-100",
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-primary font-weight-bold text-center mb-4",
                  },
                  [_vm._v("Confirm your settings")]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-column align-items-center mb-4" },
                  [
                    _c("div", { staticClass: "text-center py-2 text-xs" }, [
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v("Account name"),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass:
                            "border-bottom border-skygray px-4 d-inline-block text-center",
                        },
                        [_vm._v(_vm._s(_vm.form.name))]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column align-items-center w-100",
                  },
                  [
                    _c("div", { staticClass: "text-center py-2 w-100" }, [
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v("Channels Added"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "my-4 flex flex-column w-100" },
                        _vm._l(_vm.accounts, function (account, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass:
                                "py-2 mb-2 border-bottom w-50 mx-auto",
                            },
                            [
                              _c("i", {
                                class:
                                  "fab fa-" +
                                  account.socialNetwork +
                                  " fa-lg text-" +
                                  account.socialNetwork +
                                  " mr-2",
                              }),
                              _vm._v(" " + _vm._s(account.accountName) + " "),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "stepper w-100" },
          [_c("stepper", { ref: "stepper" })],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-content-between py-4 w-100" },
          [
            _c(
              "b-button",
              {
                staticClass: "text-white px-4",
                attrs: {
                  variant: "skygray",
                  disabled: _vm.step <= 1 || _vm.loading,
                },
                on: { click: _vm.prevStep },
              },
              [_vm._v("Prev")]
            ),
            _c(
              "b-button",
              {
                staticClass: "px-4",
                attrs: {
                  variant: "primary",
                  disabled:
                    _vm.checkNext || _vm.loading || _vm.checkValidationName,
                },
                on: { click: _vm.nextStep },
              },
              [_vm._v(_vm._s(_vm.step === 3 ? "Confirm" : "Next"))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "d-flex containerModal" },
        [
          _c("isc-modal", {
            attrs: {
              message:
                "<p>Don’t forget to <strong>“Edit settings”</strong> to select all of your fanpages and give permissions to improve your experience with ISC.</p>",
            },
            on: { sendResult: _vm.openModal },
            model: {
              value: _vm.showModal,
              callback: function ($$v) {
                _vm.showModal = $$v
              },
              expression: "showModal",
            },
          }),
        ],
        1
      ),
      _c("IscModalAlerts", {
        attrs: {
          icon: _vm.icon,
          iconColor: _vm.iconColor,
          title: _vm.title,
          paragraph: _vm.paragraph,
          buttonMessage: _vm.buttonMessage,
          noCloseBackdrop: true,
        },
        on: { sendResult: _vm.resultModalAlerts },
        model: {
          value: _vm.showAlert,
          callback: function ($$v) {
            _vm.showAlert = $$v
          },
          expression: "showAlert",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }