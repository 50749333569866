var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 bg-mainbg", attrs: { id: "accountdetail" } },
    [
      _c(
        "div",
        [
          _vm.visibilityLoader ? _c("loader-custom") : _vm._e(),
          _c(
            "bread-crumb",
            [
              _c("v-datepicker", {
                ref: "datePicker",
                staticClass: "mr-3",
                attrs: {
                  range: "",
                  type: "date",
                  clearable: false,
                  "range-separator": " - ",
                  format: "DD/MM/YYYY",
                  "disabled-date": _vm.disabledAfter,
                },
                on: { change: _vm.changeDate, open: _vm.openCalendar },
                model: {
                  value: _vm.rangeDate,
                  callback: function ($$v) {
                    _vm.rangeDate = $$v
                  },
                  expression: "rangeDate",
                },
              }),
            ],
            1
          ),
          _vm.temp == true && _vm.rangeDate.length > 1
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _vm.detail
                    ? _c(
                        "b-container",
                        {
                          staticClass: "detail-content-box",
                          attrs: { fluid: "" },
                        },
                        [
                          _c("profile-bar", {
                            attrs: {
                              "profile-name": _vm.profile.name,
                              rrss: _vm.profile.rrss,
                              logo: _vm.profile.logo,
                            },
                          }),
                          _c("resume", { attrs: { detail: _vm.detail } }),
                          _c("resume-horizontal", {
                            attrs: { accounts: _vm.detail.accounts },
                          }),
                          _c("tabs", {
                            attrs: {
                              cant: _vm.existsFacebook ? 4 : 3,
                              titles: _vm.filterTabs,
                            },
                            on: { change: _vm.changeTab },
                          }),
                          _c(
                            "div",
                            { staticClass: "w-100 bg-white p-xl-5 p-lg-2" },
                            [
                              _vm.activeTab === 0
                                ? _c("audience", {
                                    attrs: { rangeDate: _vm.rangeDate },
                                  })
                                : _vm.activeTab === 1
                                ? _c("posts", {
                                    attrs: { rangeDate: _vm.rangeDate },
                                  })
                                : _vm.activeTab === 2 && _vm.existsFacebook
                                ? _c("engaged-users", {
                                    attrs: { rangeDate: _vm.rangeDate },
                                  })
                                : _c("interactions", {
                                    attrs: { rangeDate: _vm.rangeDate },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c("div", [_vm._m(0)]),
        ],
        1
      ),
      _c("IscModalAlerts", {
        attrs: {
          icon: _vm.icon,
          iconColor: _vm.iconColor,
          title: _vm.title,
          paragraph: _vm.paragraph,
          buttonMessage: _vm.buttonMessage,
          noCloseBackdrop: true,
          refresh: true,
        },
        on: { sendResult: _vm.resultModalAlerts },
        model: {
          value: _vm.showAlert,
          callback: function ($$v) {
            _vm.showAlert = $$v
          },
          expression: "showAlert",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "row",
        staticStyle: { display: "block" },
        attrs: { id: "newpanelcc", align: "center" },
      },
      [
        _c("div", { staticClass: "box_notice" }, [
          _vm._v(
            " You need to add accounts to this brand, Go to Overview -> Manage Brands -> Edit Brand "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }